import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        accent: '#385F73',
        add: colors.blue.darken2,
        action: '#2589BD',
        alert: colors.orange,
        base: colors.shades.white,
        button: colors.shades.white,
        delete: colors.red,
        edit: colors.orange,
        highlight: '#A4AC96',
        fill: colors.grey.lighten4,
        input: colors.shades.white,
        main: colors.blueGrey.darken4,
        primary: '#2589BD',
        checkbox: '#385F73',
        save: colors.green,
        toolbar: colors.grey.lighten4,
        tab: '#37474F',
        info: colors.blue,
        error: colors.red.lighten1,
        warning: colors.orange.lighten1,
        success: colors.green
      },
      arg: {
        accent: '#2597C3',
        add: '#A71113',
        action: '#ED2127',
        alert: colors.orange,
        base: colors.shades.white,
        button: colors.shades.white,
        delete: colors.red,
        edit: colors.orange,
        highlight: '#A4AC96',
        fill: colors.grey.lighten4,
        input: colors.shades.white,
        main: '#3A3A3C',
        primary: '#1D82A8',
        checkbox: '#2597C3',
        save: colors.green,
        toolbar: colors.grey.lighten4,
        tab: '#3A3A3C',
        info: '#2597C3',
        error: '#ED2127',
        warning: colors.orange.lighten1,
        success: colors.green
      }
    }
  }
})
