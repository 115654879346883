<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-data-table
      color="#fff"
      style="cursor:pointer"
      loading-text="Loading... Please wait"
      :headers="headers"
      :height="tableSize"
      :items-per-page="pageSize"
      :items.sync="promos"
      :loading="loading"
      hide-default-footer
      fixed-header
      :sort-by="tableSort.key"
      :sort-desc="tableSort.desc"
      dense
      @update:sort-by="setTableSortKey"
      @update:sort-desc="setTableSortDesc"
      @dblclick:row="viewPromo">

      <template v-slot:no-data>
          <v-card flat v-if="!loading">
            <v-card-text v-if="!activeSearch">
              No promos to show
            </v-card-text>
            <v-card-text v-else>
              No results found
            </v-card-text>
          </v-card>
        </template>

      <template v-slot:top>
        <v-row dense align="center">
          <v-toolbar flat class="grey lighten-3">
            <v-toolbar-title>
              Promotions
            </v-toolbar-title>
            <v-col style="max-width: 300px" class="pr-1">
              <v-select
                v-model="selectedStatuses"
                label="Status"
                :items="promo_statuses"
                item-text="name"
                item-value="id"
                :menu-props="{ offsetY: true }"
                multiple
                chips
                clearable
                :disabled="loading"
                @click:clear="selectedStatuses = []"
                background-color="#fff"
                item-color="action"
                dense
                hide-details
                outlined>
                <template v-slot:selection="{ item, index }">
                  <v-chip small v-if="index === 0">
                    <span>{{ item.name }}</span>
                  </v-chip>
                  <span v-if="index === 1"
                    class="grey--text text-caption"
                  >
                    (+{{ selectedStatuses.length - 1}})
                  </span>
                </template>
              </v-select>
            </v-col>
            <v-col class="ma-0 pa-0">
              <DateFilters v-model="dateParams" :disabled="loading" />
            </v-col>
            <v-col cols="4" class="pl-1 pr-0">
              <SearchFilters v-model="searchParams" :disabled="loading" @search="getPromos" />
            </v-col>
            <v-col cols="auto" class="pl-0">
              <v-btn
                :loading="loading"
                :disabled="loading"
                height="40"
                depressed
                color="accent"
                style="border-top-left-radius: 0px; border-bottom-left-radius:0px; border: 1px solid #9e9e9e; border-left: 0px;"
                @click.stop="getPromos">
               <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-col>
              <v-btn
                v-if="hasWriteAccess"
                @click.stop="showCategoryModal = true"
                elevation="0"
                :color="promoAddColor"
                class="pl-3 pr-2 white--text"
                height="40">
                Promo <v-icon class="ml-1">mdi-plus</v-icon>
              </v-btn>
              <v-tooltip v-if="tenantUsesContractUpload" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    color="accent"
                    class="mx-2 white--text"
                    height="40"
                    small
                    fab
                    @click.stop="uploadModal = true">
                    <v-icon>mdi-file-upload-outline</v-icon>
                  </v-btn>
                </template>
                Create promotions via contract file
              </v-tooltip>
          </v-toolbar>
        </v-row>
        <v-divider />
      </template>

      <template v-slot:[`header.download`]="{ }">
        <v-menu open-on-hover offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              dark
              small
              v-on="on"
            >
              <v-icon class="mx-auto">
                mdi-download
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title class="grey--text text--darken-1">Select export type</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click.stop="exportPromos('simple')">
              <v-list-item-title>Simple</v-list-item-title>
            </v-list-item>
            <v-list-item @click.stop="exportPromos('detailed')">
              <v-list-item-title>Detailed</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:[`item.lump_sum_allowance`]="{ item }">
        <span v-if="item.lump_sum_allowance">
          ${{ item.lump_sum_allowance.toFixed(2)}}
        </span>
      </template>

      <template v-slot:[`item.start_dt`]="{ item }">
        {{ $config.formatDate(item.start_dt) }}
      </template>

      <template v-slot:[`item.end_dt`]="{ item }">
        {{ $config.formatDate(item.end_dt) }}
      </template>

      <template v-slot:[`item.ad_date`]="{ item }">
        {{ $config.formatDate(item.ad_date) }}
      </template>

       <template v-slot:[`item.download`]="{ item }">
            <button icon @click.stop="download(item)">
              <v-icon class="mx-auto">
                mdi-download
              </v-icon>
            </button>
      </template>

      <template v-slot:[`item.updated_on`]="{ item }">
        <div class="text-center">
          <v-menu
            open-on-hover
            top
            offset-y
            left>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
                class="primary--text">
              {{ updatedOn(item) }}
              </span>
            </template>
              <v-card
              class="pa-2">
                <v-list-item-title>{{ updatedBy(item) }}</v-list-item-title>
              </v-card>
          </v-menu>
        </div>
      </template>

      <template v-slot:footer>
        <PagingFooter
          :pageSizes="pageSizes.concat(tenant === 'pricechopper' ? [500] : [])"
          :pageSize.sync="pageSize"
          :page="page"
          :disablePrevious="disablePrevious"
          :disableNext="disableNext"
          :showFirstPage="showFirstPage"
          @first="setFirstPage"
          @previous="previous"
          @next="next">
        </PagingFooter>
      </template>
    </v-data-table>
    <PromoCategoryModal
      v-if="showCategoryModal"
      v-model="showCategoryModal"
      :responsiblePartyId="responsibleParty.id"
      @cancel="showCategoryModal = false">
    </PromoCategoryModal>
    <ContractUploadModal
      v-if="uploadModal"
      v-model="uploadModal"
      @closeUpload="closeUploadModal">
    </ContractUploadModal>
  </v-container>
</template>
<script>
// API
import Promos from '@/axios/promotion-endpoint'
// Mixins
import { fullWidthTable } from '@/mixins/table'
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'
// Components
import SearchFilters from '@/components/SearchFilters.vue'
import DateFilters from '@/components/DateFilters.vue'
import PagingFooter from '@/components/shared/PagingFooter'
const PromoCategoryModal = () => import('@/components/PromoCategoryModal.vue')
const ContractUploadModal = () => import('@/components/contracts/ContractUploadModal.vue')
export default {
  name: 'Promos',
  data () {
    return {
      loading: true,
      uploadModal: false,
      tableSize: '',
      windowHeight: '',
      pageSize: (this.$auth.tenant === 'pricechopper') ? 500 : 200,
      pageSizes: [25, 50, 100, 200],
      page: 1,
      promos: [],
      dateParams: {},
      searchParams: {},
      selectedStatuses: [],
      selectedPromo: null,
      showCategoryModal: false,
      tableSort: {
        key: null,
        desc: null
      }
    }
  },
  components: { PromoCategoryModal, PagingFooter, SearchFilters, DateFilters, ContractUploadModal },
  mixins: [fullWidthTable, displayAlert, userAccess],
  watch: {
    promoFilters: {
      handler (newValue) {
        if (newValue) {
          if (this.page > 1) {
            this.page = 1
          }
        }
      },
      deep: true
    },
    userRelatedPartyIds: {
      handler () {
        if (this.limitCategoriesByUserParties) {
          this.$store.dispatch('getUserCategories')
        }
      },
      deep: true
    },
    selectedStatuses: {
      handler (newValue) {
        if (newValue) {
          sessionStorage.setItem('promos_status_filter', JSON.stringify(newValue))
        } else {
          sessionStorage.removeItem('promos_status_filter')
        }
      },
      deep: true
    },
    tableSort: {
      handler(newValue) {
        if (!Object.values(newValue).every(value => !value)) {
          sessionStorage.setItem('table_sort', JSON.stringify(newValue))
        } else {
          sessionStorage.removeItem('table_sort')
        }
      },
      deep: true
    }
  },
  computed: {
    headers () {
      return [
        { text: '', sortable: false, filterable: false, class: 'accent', show: true},
        { text: 'Promotion Name', sortable: true, filterable: true, value: 'name', class: 'accent white--text', show: true },
        { text: 'Promotion Category', sortable: true, filterable: true, value: 'promo_category_name', class: 'accent white--text', show: true },
        { text: 'Promo Number', sortable: true, filterable: true, value: 'promo_number', class: 'accent white--text', show: true },
        { text: 'Ad Fee', align: 'center', sortable: true, filterable: true, value: 'lump_sum_allowance', class: 'accent white--text', show: ['pricechopper', 'alliance-retail-group'].includes(this.tenant) },
        { text: 'Start Date', sortable: true, sort: (a, b) => Date.parse(a) - Date.parse(b), filterable: true, value: 'start_dt', class: 'accent white--text', show: true }, 
        { text: 'End Date', sortable: true, sort: (a, b) => Date.parse(a) - Date.parse(b), filterable: false, value: 'end_dt', class: 'accent white--text', show: true },
        { text: 'Ad Date', sortable: true, sort: (a, b) => Date.parse(a) - Date.parse(b), filterable: false, value: 'ad_date', class: 'accent white--text', show: this.tenant === 'alliance-retail-group' },
        { text: 'Last Updated', sortable: true, filterable: true, value: 'updated_on', class: 'accent white--text', show: true },
        { text: 'Status', align: 'center', sortable: true, filterable: true, value: 'status', class: 'accent white--text', show: true },
        { text: 'Download', sortable: false, filterable: false, value: 'download', class: 'accent white--text', show: true }
      ].filter(header => header.show)
    },
    promoFilters () {
      return { ...this.searchParams, ...this.dateParams, status_ids: this.selectedStatuses }
    },
    activeSearch () {
      return Object.values(this.promoFilters).some(val => {
        return Array.isArray(val) ? (val.length > 0) : Boolean(val)
      })
    },
    activeFilter() {
      return Object.keys(sessionStorage).some(key => key?.includes('filter') && !!sessionStorage[key])
    },
    responsibleParties () {
      return this.$store.getters.responsible_parties
    },
    promo_statuses () {
      return this.$store.getters.promo_statuses.filter(status => status.constant !== 'CANCELLED')
    },
    responsibleParty () {
      return this.responsibleParties.find(party => party.tenant === this.tenant)
    },
    disablePrevious () {
      return (this.page - 1) === 0
    },
    disableNext () {
      return this.loading || (this.promos.length < this.pageSize)
    },
    showFirstPage () {
      return this.page > 1
    },
    offset () {
      return (this.page - 1) * this.pageSize
    },
    amap_excluded() {
      return this.excludeAMAPSelected || (['alliance-retail-group', 'pricechopper'].includes(this.tenant) && !this.activeSearch)
    },
    eba_excluded() {
      return this.excludeEBASelected || (['alliance-retail-group', 'pricechopper'].includes(this.tenant) && !this.activeSearch)
    },
    excludeAMAPSelected () {
      if (this.activeSearch && this.promoFilters?.promo_category_ids?.length > 0) {
        return this.promoFilters.promo_category_ids.includes('exclude-amap')
      }
      return false
    },
    excludeEBASelected () {
      if (this.activeSearch && this.promoFilters?.promo_category_ids?.length > 0) {
        return this.promoFilters.promo_category_ids.includes('exclude-eba')
      }
      return false
    },
    promoAddColor() {
      return this.tenant === 'alliance-retail-group' ? 'action' : 'primary'
    }
  },
  beforeCreate () {
    this.$store.dispatch('getPromoKeyValues')
    this.$store.dispatch('getPromoCategories')
  },
  async created () {
    if (this.$auth.tenant === 'pricechopper') this.pageSizes.push(500)
    this.resetAlert()
    this.nonTableHeight = 220.5

    await this.$store.dispatch('getResponsibleParties')
    if (this.limitAccessByUserParties && this.userRelatedPartyIds.length === 0) {
      await this.$store.dispatch('getUserRelatedParties')
    }
    if (sessionStorage.promos_status_filter) {
      this.selectedStatuses = JSON.parse(sessionStorage.promos_status_filter)
    }
    await this.getPromos()
    if (sessionStorage.table_sort) {
      this.tableSort = JSON.parse(sessionStorage.table_sort)
    }
  },
  methods: {
    setTableSortKey(event) {
      this.tableSort.key = event ? event : null
    },
    setTableSortDesc(event) {
      this.tableSort.desc = event ? event : null
    },
    getPromoTypeName (item) {
      return item?.promo_category?.name || item.promotion_type?.name
    },
    setFirstPage () {
      this.page = 1
      this.getPromos()
    },
    async getPromos () {
      if (!this.responsibleParty) return
      this.loading = true
      try {
        let payload = {}
        if (this.amap_excluded) {
          payload = { exclude_amaps: true }
        }
        if (this.eba_excluded) {
          payload = { ...payload, exclude_ebas: true }
        }
        const params = this.buildPromoSearchParams()
        const { data } = await Promos.search(params, this.offset, this.pageSize, payload)
        this.promos = data || []
      } catch (error) {
        this.handleError(error)
      } finally {
        this.loading = false
      }
    },
    buildPromoSearchParams() {
      let params = {
        responsible_party_id: this.responsibleParty.id,
        vendor_coordinator_user: this.limitAccessByRelatedVendors
      }
      if (this.limitAccessByRelatedVendors) {
        params.vendor_party_ids = this.relatedVendorPartyIds
      }
      if (this.limitAccessByRelatedSubvendors) {
        params.subvendor_party_ids = this.relatedSubvendorPartyIds
      }
      if (this.limitAccessByRelatedStores) {
        params.store_party_ids = [...this.relatedAdGroupsPartyIds, ...this.relatedDistCenterPartyIds, ...this.relatedStorePartyIds]
      }
      if (Object.entries(this.promoFilters).length > 0) {
        params = { ...params, ...this.promoFilters }
      }
      if (params?.promo_category_ids?.includes('exclude-amap') || params?.promo_category_ids?.includes('exclude-eba')) {
        params.promo_category_ids = params.promo_category_ids.filter(id => !['exclude-amap', 'exclude-eba'].includes(id))
        if (params.promo_category_ids.length === 0) {
          delete params.promo_category_ids
        }
      }
      return params
    },
    viewPromo (event, {item}) {
      const promo = item
      const promo_category = promo.promo_category
      this.$router.push({
        name: 'Promo',
        query: { id: promo.id },
        params: { promo_category }
      })
    },
    async download(promo) {
      try {
        const res =  await Promos.download_csv(promo.id)
        if (res.data){
          location.href = res.data;}
      } catch (err) {
        this.handleError(err)
      }
    },
    async exportPromos(export_type) {
      const params = this.buildPromoSearchParams()
      if (export_type === 'simple') {
        params.simple_export = true
      }
      if (this.validateExport(params)) {
        try {
          const res =  await Promos.export(params)
          if (res?.data) {
            location.href = res.data
          }
        } catch (err) {
          this.handleError(err)
        }
      }
    },
    validateExport(params) {
      // 10_713_600_000 is 124 days in milliseconds (~4 months)
      if (Object.hasOwn(params, 'start_dt') && Object.hasOwn(params, 'end_dt')) {
        if (Math.abs(Date.parse(params.end_dt) - Date.parse(params.start_dt)) <= 10713600000) {
          return true
        } else {
          this.emitAlert(true, 'warning', 'Multi-promo download is limited to 4 months')
        }
      } else {
        this.emitAlert(true, 'warning', 'Select a start and end date for multi-promo downloads')
      }
      return false
    },
    updatedBy(item){
      const updated = 'Updated By : ' + item.updated_by
      return updated
    },
    updatedOn(item){
      return this.$config.formatDate(item?.audit?.updated_on || item?.updated_on)
    },
    closeUploadModal(){
      this.uploadModal = false
    },
    previous () {
      this.page -= 1
      this.getPromos()
    },
    next () {
      this.page += 1
      this.getPromos()
    }
  }
}
</script>
<style>
.theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: #fff !important;
}

#filters-container {
  display: grid;
  align-content: center;
  grid-auto-flow: column;
  justify-content: flex-start;
}
</style>