export const formValidation = {
  computed: {
    validPromoLevel () {
      return (this.validScanPromo && this.validBillbackPromo)
    },
    validBillbackPromo () {
      return (this.promo?.promo_category?.billback_promotion)
        ? this.hasBillingAllowance(this.promo)
        : true
    },
    validScanPromo () {
      return (this.promo?.promo_category?.scan_promotion)
        ? this.hasScanAllowance(this.promo)
        : true
    },
    promoCategory () {
      if (this.promo.promo_category) {
        return this.promo.promo_category
      }
      // faster lookup for conditionally rendering billback fields
      return this.$route.params?.promo_category || null
    },
    isAMAP () {
      return this.promoCategory && this.isAMAPCategory(this.promoCategory)
    },
    isEBA () {
      return this.promoCategory && this.isEBACategory(this.promoCategory)
    },
    isLumpSum () {
      return this.promoCategory && this.isLumpSumCategory(this.promoCategory)
    },
    isAccrualPromo () {
      return this.promoCategory && this.promoCategory?.is_accrual
    },
    isNonAllowancePromo() {
      return this.isNonAllowanceCategory(this.promo?.promo_category?.name)
    },
    billbackErrorMessage () {
      return this.isAMAP
        ? 'AMAP allowance must have a value.'
        : 'Billback allowance must have a value.'
    }
  },
  methods: {
    getPriceRules (field) {
      return this.isAllowanceField(field)
        ? [this.isValidAllowancePrice]
        : [this.isValidPrice]
    },
    isValidPrice (value) {
      const validator = this.priceValidator(5, 2)
      if (value) {
        return validator(value)
      }
      return true
    },
    isValidQuantity (value) {
      if (value) {
        return (Number(value) % 1) === 0 || 'Not a positive whole number'
      }
      return true
    },
    isValidAllowancePrice (value) {
      const validator = this.priceValidator(10, 2)
      if (value) {
        return validator(value)
      }
      return true
    },
    isValidRequiredPrice (value) {
      if (value) {
        return (Number(value) > 0) ? this.isValidPrice(value) : 'Value must be greater than 0'
      }
      return 'Please provide a value'
    },
    priceValidator (prec, scale) {
      return (value) => {
        let v = `${value}`
        if (!v.includes('.')) v = `${v}.00`
        const [ints, decimals] = v.split('.')
        return ((ints.length <= prec) && (decimals.length <= scale)) || 'Invalid amount'
      }
    },
    isValidPercent (value) {
      if (value) {
        return (Number(value) <= 100) || 'Maximum value exceeded'
      }
      return true
    },
    showErrorState (field, obj) {
      if (!obj[field.value]) {
        return (this.isBillbackField(field) && this.billbackError)
        || (field.value === 'rebate_amount' && this.scanError)
      }
      return false
    },
    hasBillingAllowance (obj) {
      const fields = this.isAMAP
        ? ['amap_allowance']
        : ['billback_allowance', 'amap_allowance', 'early_buy_allowance']
      return fields.some(field => this.isValidAllowanceValue(obj[field]))
    },
    hasScanAllowance (obj) {
      return this.isValidAllowanceValue(obj?.rebate_amount)
    },
    isValidAllowanceValue (value) {
      return (value !== null) && (value !== undefined) && (value !== '')
    },
    isBillbackField (field) {
      return ['billback_allowance', 'amap_allowance', 'early_buy_allowance'].includes(field.value)
    },
    isAllowanceField (field) {
      const allowanceFields = ['billback_allowance', 'amap_allowance', 'early_buy_allowance', 'lump_sum_allowance']
      return allowanceFields.includes(field.value)
    },
    isAMAPCategory (category) {
      const { billback_promotion, name } = category
      return billback_promotion && name?.toLowerCase()?.includes('amap')
    },
    isEBACategory (category) {
      const { billback_promotion, name } = category
      return billback_promotion && name?.toLowerCase()?.includes('early buy allowance')
    },
    isLumpSumCategory (category) {
      const { billback_promotion, name } = category
      return billback_promotion && name?.toLowerCase()?.includes('lump sum')
    },
    isNonAllowanceCategory (categoryName) {
      const normalized = categoryName?.toLowerCase()?.replaceAll('-', ' ')
      return normalized?.includes('non allowance')
    },
    validateText (value) {
      const allowedChars = `@#$%&().,'- /:`.split('')
      const specialCharsRegex = /[\W_]+/
      const accentCharsRegex = /[^\x00-\x7F]+/
      if (value) {
        const chars = [...value]
        const invalidChars = chars.filter(c => {
          return (specialCharsRegex.test(c) || accentCharsRegex.test(c)) 
            && !allowedChars.includes(c)
        })
        return (invalidChars.length === 0) || `Invalid characters: ${[...new Set(invalidChars)].join(' ')}`
      }
      return true
    }
  }
}