<template>
  <v-container fluid class="ma-0 pa-0">
    <v-autocomplete
      v-if="allowMultipleSubvendors"
      v-model="localSubvendorParties"
      label="Subvendor"
      item-text="name"
      item-value="party_id"
      item-color="action"
      :items="subvendors"
      :disabled="readOnlyPromo"
      @change="subvendorSelected"
      :loading="loadingSubvendors"
      hide-no-data
      multiple
      small-chips
      clearable
      return-object
      background-color="#fff"
      dense
      outlined
      hide-details="auto">
      <template v-slot:selection="{ item, index }">
        <v-chip
          small
          v-if="index === 0"
          :style="localSubvendorParties.length > 1 ? 'max-width: 70%' : 'max-width: 90%'">
          <span class="text-truncate">
            {{ `${item.name}` }}
          </span>
        </v-chip>
        <span
          v-if="index === 1"
          style="max-width: 20%;"
          class="grey--text text-caption text-truncate">
          (+{{ localSubvendorParties.length - 1 }})
        </span>
      </template>
  </v-autocomplete>
  <v-autocomplete
    v-else
    v-model="selectedSubvendor"
    label="Subvendor"
    item-text="name"
    item-value="party_id"
    item-color="action"
    :items="subvendors"
    :disabled="readOnlyPromo"
    @change="subvendorSelected"
    :loading="loadingSubvendors"
    hide-no-data
    small-chips
    clearable
    return-object
    background-color="#fff"
    dense
    outlined
    hide-details="auto">
    <template v-slot:selection="{ item }">
        <v-chip
          small
          :style="'max-width: 90%'">
          <span class="text-truncate">
            {{ `${item.name}` }}
          </span>
        </v-chip>
      </template>
  </v-autocomplete>
  </v-container>
</template>
<script>
// api
import PartyRel from '@/axios/party-relationship-endpoint'
// mixins
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
// third party
import { cloneDeep } from 'lodash'
export default {
  data () {
    return {
      localSubvendorParties: [],
      subvendors: [],
      loadingSubvendors: false,
      selectedSubvendor: {}
    }
  },
  name: 'PromoRelatedSubvendorSelect',
  mixins: [displayAlert, utils, userAccess],
  props: {
    allowMultipleSubvendors: {
      type: Boolean,
      default: true
    },
    readOnlyPromo: Boolean,
    promoSelectedSubvendors: Array,
    promoBillingParties: Array,
    billingPartiesBuffer: Object
  },
  watch: {
    promoSelectedSubvendors: {
      handler(newVal) {
        if (newVal?.length > 0) {
          this.initSubvendors()
        }
      },
      deep: true
    },
    promoBillingParties: {
      handler(newVal) {
        if (newVal) {
          this.updateSubvendors(newVal)
        }
      },
      deep: true
    },
    billingPartiesBuffer: {
      handler(newVal) {
        if (!this.$route.query?.id && newVal) {
          const vendors = newVal?.added || []
          this.updateSubvendors(vendors)
        }
      },
      deep: true
    }
  },
  created () {
    if (this.promoSelectedSubvendors?.length > 0) {
      this.initSubvendors()
    }
  },
  methods: {
    initSubvendors () {
      this.localSubvendorParties = cloneDeep(this.promoSelectedSubvendors)
      this.subvendors = [...this.localSubvendorParties]
      if (!this.allowMultipleSubvendors) {
        this.selectedSubvendor = this.localSubvendorParties[0]
      }
    },
    subvendorSelected (newValue) {
      if (this.allowMultipleSubvendors) {
        this.$emit('subvendorSelected', newValue);
      } else {
        this.localSubvendorParties = [newValue];
        this.$emit('subvendorSelected', this.localSubvendorParties);
      }
    },
    async updateSubvendors(vendors) {
      if (vendors?.length === 0) {
        this.localSubvendorParties = []
        this.subvendors = []
        return this.$emit('subvendorSelected', this.localSubvendorParties)
      }
      let subvendors = []
      this.loadingSubvendors = true
      try {
        const partyIds = vendors.map(v => v.party_id)
        const subvendorParties = await this.getDownstreamSubvendorParties(partyIds)
        if (subvendorParties.length > 0) {
          subvendors = subvendorParties.sort((a, b) => a.name.localeCompare(b.name))
        }
        this.subvendors = subvendors
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loadingSubvendors = false
      }
    },
    async getDownstreamSubvendorParties (vendor_uuids) {
      if (vendor_uuids?.length !== 0) {
        const downstreamSubvendorRes = await PartyRel.downstreamRelationshipSearch({ to_party_ids: vendor_uuids })
        if (downstreamSubvendorRes.data?.length !== 0) {
          const downstreamSubvendors = downstreamSubvendorRes.data
            .filter(rel => rel.from_rel_type === 'CHILD_VENDOR_FOR')
            .map(rel => {
              let subvendor = {}
              subvendor.name = rel.from_party_name
              subvendor.party_name = `${subvendor.name}`
              subvendor.party_id = rel.from_party_id
              return subvendor
            })
          this.loadingSubvendors = false
          return downstreamSubvendors
        } else {
          return []
        }
      }
    },
  }
}
</script>