<template>
  <v-container fluid class="ma-0 pa-0">
    <v-autocomplete
      v-model="localSubvendorParties"
      label="Subvendor"
      item-text="name"
      item-value="party_id"
      item-color="action"
      :items="subvendors"
      :disabled="readOnlyPromo"
      @change="subvendorSelected"
      :search-input.sync="subvendorSearch"
      :loading="loadingSubvendors"
      hide-no-data
      multiple
      cache-items
      small-chips
      clearable
      return-object
      background-color="#fff"
      dense
      outlined
      hide-details="auto">
      <template v-slot:selection="{ item, index }">
        <v-chip
          small
          v-if="index === 0"
          :style="localSubvendorParties.length > 1 ? 'max-width: 70%' : 'max-width: 90%'">
          <span class="text-truncate">
            {{ `${item.name}` }}
          </span>
        </v-chip>
        <span
          v-if="index === 1"
          style="max-width: 20%;"
          class="grey--text text-caption text-truncate">
          (+{{ localSubvendorParties.length - 1 }})
        </span>
      </template>
  </v-autocomplete>
  </v-container>
</template>
<script>
// api
import Search from '@/axios/search-endpoint'
// mixins
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
// third party
import { debounce, cloneDeep } from 'lodash'
export default {
  data () {
    return {
      localSubvendorParties: [],
      subvendors: [],
      subvendorSearch: '',
      loadingSubvendors: false
    }
  },
  name: 'PromoSubvendorSelect',
  mixins: [ displayAlert, utils, userAccess],
  props: {
    readOnlyPromo: Boolean,
    promoSelectedSubvendors: Array
  },
  watch: {
    subvendorSearch: {
      handler: debounce(function(newValue) {
        if (newValue) {
          this.searchSubvendors()
        }
      }, 200)
    },
    promoSelectedSubvendors: {
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.initSubvendors()
        } else {
          this.searchSubvendors()
        }
      },
      deep: true
    },
  },
  created() {
    if (this.promoSelectedSubvendors === 0) {
      this.searchSubvendors()
    } else {
      this.initSubvendors()
    }
  },
  methods: {
    async initSubvendors () {
      this.localSubvendorParties = cloneDeep(this.promoSelectedSubvendors).flatMap(item => item)
      this.subvendors = [ ...this.localSubvendorParties ]
    },
    async searchSubvendors () {
      this.loadingSubvendors = true
      let subvendors = []
      const { term, operator } = this.buildSubvendorSearchParams()
      try{
        let { data } = await Search.customerSearch(term, operator)
        if(data?.length > 0) {
          subvendors = data.flatMap(party => {
            if(party.party_type === 'SUBVENDOR' && (party.attributes?.SUBVENDOR_IS_ACTIVE === 'True' )) {
              party.name = party.party_name
              return party
            }
            return []
          })
        }
        this.subvendors = [...this.subvendors, ...subvendors]
      }  catch (err) {
        this.handleError(err)
      } finally {
        this.loadingSubvendors = false
      }
    },
    buildSubvendorSearchParams () {
      let term = 'SUBVENDOR'
      let operator = 'and'
      if(this.subvendorSearch) {
        term = this.subvendorSearch
      }
      return { term, operator }
    },
    subvendorSelected (newValue) {
      this.$emit('subvendorSelected', newValue)
    },
  }
}
</script>