var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c("v-autocomplete", {
        attrs: {
          label: "Subvendor",
          "item-text": "name",
          "item-value": "party_id",
          "item-color": "action",
          items: _vm.subvendors,
          disabled: _vm.readOnlyPromo,
          "search-input": _vm.subvendorSearch,
          loading: _vm.loadingSubvendors,
          "hide-no-data": "",
          multiple: "",
          "cache-items": "",
          "small-chips": "",
          clearable: "",
          "return-object": "",
          "background-color": "#fff",
          dense: "",
          outlined: "",
          "hide-details": "auto"
        },
        on: {
          change: _vm.subvendorSelected,
          "update:searchInput": function($event) {
            _vm.subvendorSearch = $event
          },
          "update:search-input": function($event) {
            _vm.subvendorSearch = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function(ref) {
              var item = ref.item
              var index = ref.index
              return [
                index === 0
                  ? _c(
                      "v-chip",
                      {
                        style:
                          _vm.localSubvendorParties.length > 1
                            ? "max-width: 70%"
                            : "max-width: 90%",
                        attrs: { small: "" }
                      },
                      [
                        _c("span", { staticClass: "text-truncate" }, [
                          _vm._v(" " + _vm._s("" + item.name) + " ")
                        ])
                      ]
                    )
                  : _vm._e(),
                index === 1
                  ? _c(
                      "span",
                      {
                        staticClass: "grey--text text-caption text-truncate",
                        staticStyle: { "max-width": "20%" }
                      },
                      [
                        _vm._v(
                          " (+" +
                            _vm._s(_vm.localSubvendorParties.length - 1) +
                            ") "
                        )
                      ]
                    )
                  : _vm._e()
              ]
            }
          }
        ]),
        model: {
          value: _vm.localSubvendorParties,
          callback: function($$v) {
            _vm.localSubvendorParties = $$v
          },
          expression: "localSubvendorParties"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }