var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-expansion-panels",
        {
          attrs: { flat: "" },
          model: {
            value: _vm.expandedPanel,
            callback: function($$v) {
              _vm.expandedPanel = $$v
            },
            expression: "expandedPanel"
          }
        },
        [
          _c(
            "v-expansion-panel",
            [
              _c(
                "v-tooltip",
                {
                  attrs: { top: "", color: "primary" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-expansion-panel-header",
                            _vm._g(
                              {
                                attrs: { color: "grey lighten-3" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function() {
                                        return [
                                          _c(
                                            "v-row",
                                            { attrs: { align: "center" } },
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass: "returnLink",
                                                  attrs: {
                                                    to: { path: "/promos" }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "primary"
                                                      }
                                                    },
                                                    [_vm._v("mdi-chevron-left")]
                                                  ),
                                                  _vm._v(
                                                    " Return to Promotions "
                                                  )
                                                ],
                                                1
                                              ),
                                              _c("v-spacer"),
                                              _vm.show
                                                ? _c(
                                                    "small",
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          "Promotion Dates:"
                                                        )
                                                      ]),
                                                      _vm._v(
                                                        " ( " +
                                                          _vm._s(
                                                            _vm.$config.formatDate(
                                                              _vm.promo.start_dt
                                                            )
                                                          ) +
                                                          " - " +
                                                          _vm._s(
                                                            _vm.$config.formatDate(
                                                              _vm.promo.end_dt
                                                            )
                                                          ) +
                                                          " ) "
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "#000"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-circle-small"
                                                          )
                                                        ]
                                                      ),
                                                      _c("strong", [
                                                        _vm._v(
                                                          "Promotion Name:"
                                                        )
                                                      ]),
                                                      _vm._v(
                                                        " ( " +
                                                          _vm._s(
                                                            _vm.promo.name
                                                          ) +
                                                          " ) "
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "#000"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-circle-small"
                                                          )
                                                        ]
                                                      ),
                                                      _c("strong", [
                                                        _vm._v(
                                                          "Promotion Category:"
                                                        )
                                                      ]),
                                                      _vm._v(
                                                        " ( " +
                                                          _vm._s(
                                                            _vm.promoTypeDisplayName
                                                          ) +
                                                          " ) "
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "#000"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-circle-small"
                                                          )
                                                        ]
                                                      ),
                                                      _c("strong", [
                                                        _vm._v(
                                                          "Promotion Number:"
                                                        )
                                                      ]),
                                                      _vm._v(
                                                        " ( " +
                                                          _vm._s(
                                                            _vm.promo
                                                              .promo_number
                                                          ) +
                                                          " ) "
                                                      ),
                                                      _vm.promo.status
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "#000"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-circle-small"
                                                                  )
                                                                ]
                                                              ),
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Status:"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " ( " +
                                                                  _vm._s(
                                                                    _vm.promo
                                                                      .status
                                                                      .status
                                                                      .name
                                                                  ) +
                                                                  " ) "
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "#000"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-circle-small"
                                                          )
                                                        ]
                                                      ),
                                                      _c("strong", [
                                                        _vm._v(
                                                          "Last updated by:"
                                                        )
                                                      ]),
                                                      _vm._v(
                                                        " ( " +
                                                          _vm._s(
                                                            _vm.promo.updated_by
                                                          ) +
                                                          " ) "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c("v-spacer")
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              on
                            )
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "span",
                    [
                      _c("v-icon", { attrs: { small: "", color: "#fff" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.show
                                ? "mdi-arrow-expand-vertical"
                                : "mdi-arrow-collapse-vertical"
                            ) +
                            " "
                        )
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.show
                              ? "Expand Promotion Details"
                              : "Collapse Promotion Details"
                          ) +
                          " "
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "v-expansion-panel-content",
                { attrs: { color: "grey lighten-3" } },
                [
                  _vm._v(" Promotion Details "),
                  _c("span", { staticClass: "ml-5 text-body-2" }, [
                    _c("b", [_vm._v("Promotion Category:")]),
                    _vm._v(" " + _vm._s(_vm.promoTypeDisplayName) + " ")
                  ]),
                  _vm.promo.status
                    ? _c(
                        "span",
                        {
                          staticClass: "text-body-2 ml-4 mr-6",
                          staticStyle: { position: "relative" }
                        },
                        [
                          _c("b", [_vm._v("Status:")]),
                          _vm._v(
                            " " + _vm._s(_vm.promo.status.status.name) + " "
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              staticStyle: { top: "-10px" },
                              attrs: {
                                absolute: "",
                                fab: "",
                                "x-small": "",
                                elevation: "2"
                              },
                              on: {
                                click: function($event) {
                                  _vm.showStatusModal = true
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.readOnlyPromo &&
                                        !_vm.isPromoStatusAdmin
                                        ? "mdi-view-headline"
                                        : "mdi-pencil"
                                    ) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.promo.audit
                    ? [
                        _c("span", { staticClass: "ml-6 text-body-2" }, [
                          _c("b", [_vm._v("Last updated by:")]),
                          _vm._v(" " + _vm._s(_vm.promo.audit.updated_by) + " ")
                        ]),
                        _c("span", { staticClass: "ml-4 text-body-2" }, [
                          _c("b", [_vm._v("Created On:")]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$config.formatDate(
                                  _vm.promo.audit.created_on
                                )
                              ) +
                              " "
                          )
                        ]),
                        _c("span", { staticClass: "ml-4 text-body-2" }, [
                          _c("b", [_vm._v("Updated On:")]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$config.formatDate(
                                  _vm.promo.audit.updated_on
                                )
                              ) +
                              " "
                          )
                        ])
                      ]
                    : _vm._e(),
                  _vm.promo.cloned_from_promo_id
                    ? _c(
                        "span",
                        { staticClass: "ml-4 text-body-2" },
                        [
                          _c(
                            "router-link",
                            {
                              staticStyle: { "text-decoration": "none" },
                              attrs: {
                                to: {
                                  name: "Promo",
                                  query: { id: _vm.promo.cloned_from_promo_id }
                                }
                              }
                            },
                            [
                              _vm._v(" Parent "),
                              _c(
                                "v-icon",
                                { attrs: { small: "", color: "primary" } },
                                [_vm._v("mdi-launch")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("PromoHeader", {
                    ref: "promoTypeFields",
                    attrs: {
                      promo_type_constant: _vm.promo_type_constant,
                      promo: _vm.promo,
                      readOnlyPromo: _vm.readOnlyPromo
                    }
                  }),
                  _c(
                    "v-form",
                    { ref: "form" },
                    [
                      _c(
                        "v-row",
                        { staticClass: "promoRow" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "px-0 py-0", attrs: { cols: "4" } },
                            [
                              _c("PromoDates", {
                                ref: "promoDates",
                                attrs: {
                                  promo: _vm.promo,
                                  readOnlyPromo: _vm.readOnlyPromo
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "px-1", attrs: { cols: "2" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  label: "Promotion Type",
                                  "item-value": "id",
                                  "item-text": "name",
                                  "item-color": "action",
                                  items: _vm.billingModeOptions,
                                  "background-color": "#fff",
                                  rules: [_vm.isValidBillingMode],
                                  readonly: _vm.readOnlyPromo,
                                  dense: "",
                                  "hide-details": "auto",
                                  outlined: "",
                                  "return-object": ""
                                },
                                model: {
                                  value: _vm.promo.billing_mode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.promo, "billing_mode", $$v)
                                  },
                                  expression: "promo.billing_mode"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "px-1", attrs: { cols: "2" } },
                            [
                              _c("PromoVendorSelect", {
                                ref: "vendorSelect",
                                attrs: {
                                  promoBillingParties: _vm.promoBillingParties,
                                  readOnlyPromo: _vm.readOnlyPromo
                                },
                                on: {
                                  queueChanges: _vm.queueBillingPartyChanges
                                }
                              })
                            ],
                            1
                          ),
                          _vm.renderPromoSubvendorSelect &&
                          !_vm.restrictToRelatedSubvendors
                            ? [
                                _c(
                                  "v-col",
                                  { staticClass: "px-1", attrs: { cols: "2" } },
                                  [
                                    _c("PromoSubvendorSelect", {
                                      ref: "subvendorSelect",
                                      attrs: {
                                        promoBillingParties:
                                          _vm.promoBillingParties,
                                        promoSelectedSubvendors:
                                          _vm.promoSelectedSubvendors,
                                        readOnlyPromo: _vm.readOnlyPromo
                                      },
                                      on: {
                                        subvendorSelected:
                                          _vm.handleSubvendorSelected
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            : _vm.renderPromoSubvendorSelect &&
                              _vm.restrictToRelatedSubvendors
                            ? [
                                _c(
                                  "v-col",
                                  { staticClass: "px-1", attrs: { cols: "2" } },
                                  [
                                    _c("PromoRelatedSubvendorSelect", {
                                      ref: "subvendorSelect",
                                      attrs: {
                                        promoBillingParties:
                                          _vm.promoBillingParties,
                                        promoSelectedSubvendors:
                                          _vm.promoSelectedSubvendors,
                                        readOnlyPromo: _vm.readOnlyPromo,
                                        billingPartiesBuffer:
                                          _vm.billingPartiesBuffer,
                                        allowMultipleSubvendors:
                                          _vm.allowMultipleSubvendors
                                      },
                                      on: {
                                        subvendorSelected:
                                          _vm.handleSubvendorSelected
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            : _vm._e(),
                          !_vm.limitAccessByRelatedVendors
                            ? _c(
                                "v-col",
                                { staticClass: "px-1", attrs: { cols: "2" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      label: "Classification",
                                      "item-value": "id",
                                      "item-text": "name",
                                      "item-color": "action",
                                      items: _vm.promo_classes,
                                      disabled: _vm.readOnlyPromo,
                                      "background-color": "#fff",
                                      dense: "",
                                      "hide-details": "",
                                      outlined: "",
                                      "return-object": ""
                                    },
                                    model: {
                                      value: _vm.promo.classification,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.promo,
                                          "classification",
                                          $$v
                                        )
                                      },
                                      expression: "promo.classification"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        2
                      ),
                      _c(
                        "v-row",
                        { staticClass: "promoRow", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: _vm.limitAccessByRelatedVendors ? 4 : 3
                              }
                            },
                            [
                              !_vm.id
                                ? _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      "hide-details": "",
                                      "background-color": "#fff",
                                      label: "Promotion Number"
                                    },
                                    model: {
                                      value: _vm.promo.promo_number,
                                      callback: function($$v) {
                                        _vm.$set(_vm.promo, "promo_number", $$v)
                                      },
                                      expression: "promo.promo_number"
                                    }
                                  })
                                : _c("span", { staticClass: "text-body-2" }, [
                                    _c("b", [_vm._v("Promotion Number:")]),
                                    _vm._v(
                                      " " + _vm._s(_vm.promo.promo_number) + " "
                                    )
                                  ])
                            ],
                            1
                          ),
                          !(_vm.tenant === "pricechopper")
                            ? _c(
                                "v-col",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.limitAccessByRelatedVendors,
                                      expression: "!limitAccessByRelatedVendors"
                                    }
                                  ],
                                  staticClass: "py-0 mt-4"
                                },
                                [
                                  _c(
                                    "v-layout",
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "mt-0",
                                        attrs: {
                                          color: "checkbox",
                                          height: "30px",
                                          label: "Override Store Offset",
                                          disabled: _vm.readOnlyPromo
                                        },
                                        model: {
                                          value:
                                            _vm.promo.override_store_offset,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.promo,
                                              "override_store_offset",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "promo.override_store_offset"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          !(_vm.tenant === "pricechopper")
                            ? _c(
                                "v-col",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.limitAccessByRelatedVendors,
                                      expression: "!limitAccessByRelatedVendors"
                                    }
                                  ],
                                  staticClass: "py-0 mt-4"
                                },
                                [
                                  _c(
                                    "v-layout",
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "mt-0",
                                        attrs: {
                                          color: "checkbox",
                                          height: "30px",
                                          label: "Override Store Ad Length",
                                          disabled: _vm.readOnlyPromo
                                        },
                                        model: {
                                          value:
                                            _vm.promo.override_store_length,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.promo,
                                              "override_store_length",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "promo.override_store_length"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          !["pricechopper"].includes(_vm.tenant)
                            ? _c(
                                "v-col",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.promo.promo_category &&
                                        _vm.promo.promo_category.scan_promotion,
                                      expression:
                                        "promo.promo_category && promo.promo_category.scan_promotion"
                                    }
                                  ],
                                  staticClass: "py-0 mt-4"
                                },
                                [
                                  _c(
                                    "v-layout",
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "mt-0",
                                        attrs: {
                                          color: "checkbox",
                                          height: "30px",
                                          label: "Enforce Compliance",
                                          disabled:
                                            _vm.tenant ===
                                              "alliance-retail-group" ||
                                            _vm.readOnlyPromo
                                        },
                                        on: {
                                          change: _vm.enforceComplianceChanged
                                        },
                                        model: {
                                          value: _vm.promo.enforce_compliance,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.promo,
                                              "enforce_compliance",
                                              $$v
                                            )
                                          },
                                          expression: "promo.enforce_compliance"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          !["pricechopper"].includes(_vm.tenant)
                            ? _c(
                                "v-col",
                                { staticClass: "py-0 mt-4" },
                                [
                                  _c(
                                    "v-layout",
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "mt-0",
                                        attrs: {
                                          color: "checkbox",
                                          height: "30px",
                                          label: "Perishable",
                                          disabled: _vm.readOnlyPromo
                                        },
                                        model: {
                                          value: _vm.promo.perishable,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.promo,
                                              "perishable",
                                              $$v
                                            )
                                          },
                                          expression: "promo.perishable"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-spacer"),
                          _vm.showContractDownloadButton
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "mr-4",
                                                  attrs: {
                                                    fab: "",
                                                    small: "",
                                                    elevation: "2"
                                                  },
                                                  on: {
                                                    click: _vm.downloadContract
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "mx-auto" },
                                                  [
                                                    _vm._v(
                                                      " mdi-file-download "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    4240751912
                                  )
                                },
                                [_c("span", [_vm._v("Download Contract")])]
                              )
                            : _vm._e(),
                          _vm.showShuttleButton && !_vm.readOnlyPromo
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "mr-4 white--text",
                                                  attrs: {
                                                    fab: "",
                                                    small: "",
                                                    color: "#222",
                                                    elevation: "2"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.showShuttleModal = true
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-bus-multiple")
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    4189073776
                                  )
                                },
                                [_c("span", [_vm._v("Shuttle Promo")])]
                              )
                            : _vm._e(),
                          _vm.promo.id
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "mr-4",
                                                  attrs: {
                                                    fab: "",
                                                    small: "",
                                                    elevation: "2"
                                                  },
                                                  on: { click: _vm.download }
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "mx-auto" },
                                                  [_vm._v(" mdi-download ")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    4270161553
                                  )
                                },
                                [_c("span", [_vm._v("Download Promo")])]
                              )
                            : _vm._e(),
                          _vm.promo.id
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "mr-4",
                                                  attrs: {
                                                    disabled: _vm.itemsDisabled,
                                                    fab: "",
                                                    small: "",
                                                    elevation: "2"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.showCloneModal = true
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    "mdi-plus-box-multiple-outline"
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2336694727
                                  )
                                },
                                [_c("span", [_vm._v("Copy Promo")])]
                              )
                            : _vm._e(),
                          !_vm.readOnlyPromo
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "mr-2 white--text",
                                                  attrs: {
                                                    loading: _vm.saving,
                                                    disabled: _vm.saving,
                                                    fab: "",
                                                    small: "",
                                                    elevation: "2",
                                                    color: "green"
                                                  },
                                                  on: { click: _vm.savePromo }
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { dense: "" } },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.id
                                                            ? "mdi-update"
                                                            : " mdi-content-save-outline"
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    943154737
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.id ? "Update" : "Create") +
                                        " Promo"
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm.id &&
                          _vm.hasScope("promo:delete") &&
                          !_vm.readOnlyPromo
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "white--text mx-1",
                                                  attrs: {
                                                    fab: "",
                                                    small: "",
                                                    elevation: "2",
                                                    color: "red"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.confirmationModal = true
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    "mdi-trash-can-outline"
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    948483839
                                  )
                                },
                                [_c("span", [_vm._v("Delete Promo")])]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      [
        _c("v-divider"),
        _c(
          "v-tabs",
          {
            attrs: {
              grow: "",
              dark: "",
              "background-color": "tab",
              height: "40",
              "hide-slider": "",
              "active-class": "active"
            },
            model: {
              value: _vm.tab,
              callback: function($$v) {
                _vm.tab = $$v
              },
              expression: "tab"
            }
          },
          [
            _c("v-tab", { attrs: { disabled: !_vm.existingPromo } }, [
              _vm._v("Group / Store")
            ]),
            _c(
              "v-tab",
              { attrs: { disabled: !_vm.existingPromo || _vm.itemsDisabled } },
              [_vm._v("Items")]
            )
          ],
          1
        ),
        _c(
          "keep-alive",
          [
            _vm.tab === 0
              ? _c("storeTable", {
                  attrs: {
                    panel_height: _vm.panelHeight - 36.5,
                    promo: _vm.promo,
                    readOnlyPromo: _vm.readOnlyPromo,
                    partyItemSources: _vm.partyItemSources,
                    upstreamPartyIds: _vm.upstreamPartyIds
                  },
                  on: {
                    enableItemsTab: function($event) {
                      _vm.itemsDisabled = false
                    },
                    setPromoPartyIds: _vm.setPromoPartyIds,
                    updateItemSources: _vm.updatePartyItemSources,
                    updateShuttleEnabled: function(val) {
                      return (_vm.shuttleEnabled = val)
                    },
                    setPromoAllowances: function(val) {
                      return (_vm.promoAllowances = val)
                    },
                    updateHistory: _vm.updatePromoHistory
                  }
                })
              : _c("itemTable", {
                  attrs: {
                    panel_height: _vm.panelHeight,
                    promo: _vm.promo,
                    readOnlyPromo: _vm.readOnlyPromo,
                    promo_type_constant: _vm.promo_type_constant,
                    item_sources: _vm.promoItemSources,
                    vmc_sources: _vm.vmcSources,
                    vendorIds: _vm.vendorIds
                  },
                  on: { updateHistory: _vm.updatePromoHistory }
                })
          ],
          1
        ),
        _c(
          "v-dialog",
          {
            attrs: { width: "500" },
            model: {
              value: _vm.confirmationModal,
              callback: function($$v) {
                _vm.confirmationModal = $$v
              },
              expression: "confirmationModal"
            }
          },
          [
            _c(
              "v-card",
              [
                _c("v-card-title", { staticClass: "headline grey lighten-2" }, [
                  _vm._v(" Are You Sure? ")
                ]),
                _c("v-card-text", [
                  _vm._v(
                    " Deleting a promo is a permanent action than cannot be undone. Are you sure you want to delete this promo? "
                  )
                ]),
                _c("v-divider"),
                _c(
                  "v-card-actions",
                  [
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "red darken-1 white--text" },
                        on: {
                          click: function($event) {
                            _vm.confirmationModal = false
                          }
                        }
                      },
                      [_vm._v(" No, don't delete! ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          disabled: _vm.deleting,
                          loading: _vm.deleting,
                          color: "green darken-1 white--text"
                        },
                        on: {
                          click: function($event) {
                            return _vm.deletePromo()
                          }
                        }
                      },
                      [_vm._v(" Yes, I am sure! ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm.showCloneModal
          ? _c("CloneModal", {
              attrs: { promo: _vm.promo },
              on: {
                success: _vm.initClonedPromo,
                cancel: function($event) {
                  _vm.showCloneModal = false
                }
              },
              model: {
                value: _vm.showCloneModal,
                callback: function($$v) {
                  _vm.showCloneModal = $$v
                },
                expression: "showCloneModal"
              }
            })
          : _vm._e(),
        _vm.showShuttleModal
          ? _c("ShuttleModal", {
              attrs: {
                promoPartyIds: _vm.promoPartyIds,
                partyItemSources: _vm.partyItemSources
              },
              on: {
                close: function($event) {
                  _vm.showShuttleModal = false
                }
              },
              model: {
                value: _vm.showShuttleModal,
                callback: function($$v) {
                  _vm.showShuttleModal = $$v
                },
                expression: "showShuttleModal"
              }
            })
          : _vm._e(),
        _vm.showStatusModal
          ? _c("StatusModal", {
              attrs: {
                promo: _vm.promo,
                vendorIds: _vm.vendorIds,
                validLumpSums: _vm.validLumpSums,
                lumpSumTotal: _vm.lumpSumTotal,
                validSubvendorSelected: _vm.validSubvendorSelected,
                validCategoryManagerSelected: _vm.validCategoryManagerSelected,
                isPromoStatusAdmin: _vm.isPromoStatusAdmin,
                readOnlyPromo: _vm.readOnlyPromo
              },
              on: {
                close: function($event) {
                  _vm.showStatusModal = false
                },
                saved: _vm.statusSaved
              },
              model: {
                value: _vm.showStatusModal,
                callback: function($$v) {
                  _vm.showStatusModal = $$v
                },
                expression: "showStatusModal"
              }
            })
          : _vm._e()
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }