<template>
  <v-row dense class="ma-0 pa-0">
    <v-col v-for="(filter, i) in [startDate, endDate]" :key="i">
      <v-menu
        :close-on-content-click="false"
        @input="filter.type === 'start' ? startDateMenu = true : endDateMenu = true"
        transition="scale-transition"
        offset-y
        nudge-top="0"
        min-width="auto"
        :value="filter.type === 'start' ? startDateMenu : endDateMenu">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="dateFormatted(filter.value)"
            :label="filter.type === 'start' ? startLabel : endLabel"
            v-bind="attrs"
            v-on="on"
            hide-details
            :color="activeRange ? 'deep-purple accent-2' : 'primary'"
            prepend-inner-icon="mdi-calendar"
            background-color="#fff"
            readonly
            :disabled="disabled"
            outlined
            dense
            clearable
            @click:clear="clearFilter(filter)">
          </v-text-field>
        </template>
        <v-card flat>
          <div class="static-menu-content">
            <v-date-picker
              v-model="filter.value"
              :color="activeRange ? 'deep-purple accent-2' : 'action'"
              :max="filter.type === 'start' ? maxStartDate : null"
              :min="filter.type === 'end' ? minEndDate : null"
              :show-current="filter.type === 'end' ? startDate.value : endDate.value"
              no-title>
            </v-date-picker>
            <v-divider inset vertical></v-divider>
            <div>
              <v-card-title class="text-subtitle-2">
                {{ filter.label }}
              </v-card-title>
              <v-card-subtitle v-if="activeRange">
                Find promotions active between {{ activeRangeText(filter) }}
              </v-card-subtitle>
              <v-card-subtitle v-else>
                {{ filter.desc }}
              </v-card-subtitle>
              <v-divider></v-divider>
              <v-list flat three-line>
                <v-list-item :disabled="activeRange">
                  <v-list-item-content>
                    <v-list-item-title>
                      Exact
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Promo {{ filter.type }} date must match the date selected.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                    <v-list-item-action>
                      <v-switch
                        v-model="filter.exact"
                        :disabled="activeRange"
                        color="action"
                        hide-details
                        dense
                        class="my-auto">
                      </v-switch>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item :disabled="startDate.exact || endDate.exact || !startDate.value || !endDate.value">
                  <v-list-item-content>
                    <v-list-item-title>
                      Active range
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Promo start/end dates must overlap the current date range.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                    <v-list-item-action>
                      <v-switch
                        v-model="activeRange"
                        :disabled="startDate.exact || endDate.exact || !startDate.value || !endDate.value"
                        hide-details
                        color="deep-purple accent-2"
                        dense
                        class="my-auto">
                      </v-switch>
                    </v-list-item-action>
                </v-list-item>
              </v-list>
            </div>
          </div>
          <v-card-actions
            v-if="filter.value"
            style="position: absolute; right: 0; bottom: 0;"
            class="mt-0 pt-0">
            <v-btn
              text
              @click="clearFilter(filter)">
              Clear
            </v-btn>
            <v-btn
              color="action"
              text
              @click="confirm(filter)">
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data () {
    return {
      activeRange: false,
      startDateMenu: false,
      endDateMenu: false,
      startDate: {
        value: null,
        label: 'Start Date',
        type: 'start',
        exact: false,
        desc: 'Find promotions with start dates on or after the selected date.'
      },
      endDate: {
        value: null,
        label: 'End Date',
        type: 'end',
        exact: false,
        desc: 'Find promotions with end dates on or before the selected date.'
      }
    }
  },
  watch: {
    startDate: {
      handler (newValue) {
        if (newValue)  {
          this.buildDateQueries()
          if (newValue.value) {
            sessionStorage.setItem('promos_start_dt_filter', JSON.stringify(newValue))
          } else {
            sessionStorage.removeItem('promos_start_dt_filter')
          }
        }
      },
      deep: true
    },
    endDate: {
      handler (newValue) {
        if (newValue)  {
          this.buildDateQueries()
          if (newValue.value) {
            sessionStorage.setItem('promos_end_dt_filter', JSON.stringify(newValue))
          } else {
            sessionStorage.removeItem('promos_end_dt_filter')
          }
        }
      },
      deep: true
    },
    activeRange: {
      handler (newValue) {
        this.buildDateQueries()
        if (newValue === false) {
          sessionStorage.removeItem('promos_active_range_filter')
        } else {
          sessionStorage.setItem('promos_active_range_filter', newValue)
        }
      }
    }
  },
  name: 'DateFilters',
  props: {
    value: Object,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    maxStartDate () {
      return this.endDate.value
    },
    minEndDate () {
      return this.startDate.value
    },
    startLabel () {
      return this.getDisplayLabel(this.startDate)
    },
    endLabel () {
      return this.getDisplayLabel(this.endDate)
    }
  },
  created () {
    if (sessionStorage.promos_start_dt_filter) {
      const sessionStartDate = JSON.parse(sessionStorage.promos_start_dt_filter)
      this.startDate.value = sessionStartDate.value
      this.startDate.exact = sessionStartDate.exact
    }
    if (sessionStorage.promos_end_dt_filter) {
      const sessionEndDate = JSON.parse(sessionStorage.promos_end_dt_filter)
      this.endDate.value = sessionEndDate.value
      this.endDate.exact = sessionEndDate.exact
    }
    if (sessionStorage.promos_active_range_filter) {
      this.activeRange = JSON.parse(sessionStorage.promos_active_range_filter)
    }
  },
  methods: {
    activeRangeText (filter) {
      return (filter.type === 'start')
        ? `the selected date and ${this.dateFormatted(this.endDate.value)}.`
        : `${this.dateFormatted(this.startDate.value)} and the selected date.`
    },
    getDisplayLabel (filter) {
      let label = filter.label
      if (this.activeRange) label = `${label} (Active)`
      else if (filter.exact) label = `${label} (Exact)`
      return label
    },
    clearFilter (filter) {
      filter.value = null
      filter.exact = false
      if (this.activeRange) this.activeRange = false
    },
    dateFormatted (dateVal) {
      if (dateVal) {
        return this.$config.formatDate(dateVal)
      }
      return dateVal
    },
    buildDateQueries () {
      const params = {}
      if (this.startDate.value) {
        params.start_dt = this.startDate.value
        if (this.startDate.exact) {
          params.exact_dt = true
        }
      }
      if (this.endDate.value) {
         params.end_dt = this.endDate.value
        if (this.endDate.exact) {
          params.exact_dt = true
        }
      }
      if (this.activeRange) {
        params.active_range = this.activeRange
      }
      this.$emit('input', params)
    },
    confirm (filter) {
      if (filter.type === 'start') {
        this.startDateMenu = false
      } else {
        this.endDateMenu = false
      }
    }
    /*
    buildDateRangeQueries () {
      const startQuery = {
        range: {'start_dt': {'lt': this.endDate.value }}
      }
      const endQuery = {
        range: {'end_dt': {'gt': this.startDate.value }}
      }
      return [startQuery, endQuery]
    },
    buildStartDateQuery () {
      const termQuery = { 
        term: {'start_dt': this.startDate.value }
      }
      const rangeQuery = {
        range: {'start_dt': {'gte': this.startDate.value }}
      }
      if (this.endDate.value) {
        rangeQuery.range.start_dt['lt'] = this.endDate.value
      }
      return this.startDate.exact ? termQuery : rangeQuery
    },
    buildEndDateQuery () {
      const termQuery = {
        term: {'end_dt': this.endDate.value }
      }
      const rangeQuery = {
        range: {'end_dt': {'lte': this.endDate.value }}
      }
      if (this.startDate.value) {
        rangeQuery.range.end_dt['gt'] = this.startDate.value
      }
      return this.endDate.exact ? termQuery : rangeQuery
    }
    */
  }
}
</script>
<style>
.static-menu-content {
  display: grid;
  align-content: center;
  justify-content: flex-start;
  grid-auto-columns: auto;
  min-width: 100%;
  max-width: 590px;
  padding-top: 5px;
  padding-bottom: 25px;
  grid-auto-flow: column;
  position: relative;
}
</style>