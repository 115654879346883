<template>
  <v-overlay v-if="$auth.isLoading || !$auth.isAuthenticated">
    <v-progress-circular
      indeterminate
      size="64">
    </v-progress-circular>
  </v-overlay>

  <v-app v-else id="inspire">
    <v-app-bar color="main" dark app clipped-left>
      <v-app-bar-nav-icon
        v-show="$auth.isAuthenticated"
        class="mx-auto"
        @click.stop="drawer = !drawer">
      </v-app-bar-nav-icon>
        <router-link :to="{ path: '/'}">
          <v-img v-if="tenantsWithLogos.includes(`${$auth.tenant}`)"
            max-height="110"  
            max-width="110"
            class="ml-6"
            :src="require(`@/assets/${$auth.tenant}_logo.png`)" 
            :alt="`${$auth.tenant} Logo`"> 
          </v-img>
          <v-app-bar-nav-icon v-else>
            <v-icon color="#fff">mdi-home</v-icon>
          </v-app-bar-nav-icon>
        </router-link>
      <v-spacer/>
      <VideoMenu v-if="tenant === 'awg'" />
      <v-col cols="auto" class="pr-0">
        <PortalNavMenu active="promo" />
      </v-col>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      clipped
      color="grey lighten-3"
      width="200"
      app>
      <v-list background-color="#fff">
        <v-list-item
          v-for="(link, i) in navLinks"
          :key="i"
          :to="{ path: link.path }"
          link>
          <v-icon>{{ link.icon }}</v-icon>
          <v-list-item-content>
            <v-list-item-title class="ml-2">
              {{ link.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-divider></v-divider>
        <div :class="[(!miniVariant) ? 'pa-2': 'pa-1']">
          <v-btn block id="logoutBtn"
            :style="styleObject"
            @click.prevent="logout()">
            <v-icon color="#fff">mdi-logout</v-icon>
            <span v-if="!miniVariant">Logout</span>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <Maintenance v-if="maintenanceStatus" />

    <v-main v-else class="grey lighten-3">
      <alertbar></alertbar>
      <router-view v-if="hasPortalAccess" />
      <v-alert
        v-else
        type="error"
        text
        border="left">
        You don't have authorized permissions to access this site.
      </v-alert>
    </v-main>
    <v-footer color="main" app class="justify-space-between">
      <span class="white--text">iPro <span class="registeredTrademark">&reg;</span> Systems, LLC. &copy; {{ new Date().getFullYear() }}</span>
      <span class="white--text">v. {{version}}</span>
    </v-footer>
  </v-app>
</template>
<script>
// components
import alertbar from '@/components/shared/alertbar.vue'
const VideoMenu = () => import('@/components/VideoMenu.vue')
const Maintenance = () => import('@/components/shared/Maintenance.vue')
const PortalNavMenu = () => import('@/components/shared/PortalNavMenu.vue')
import { userAccess } from '@/mixins/user-access'
export default {
  name: 'App',
  data () {
    return {
      drawer: false,
      miniVariant: false,
      styleObject: {
        background: '#E53935',
        color: 'white',
        fontSize: '15px'
      },
      storedKeys: ['search_filters', 'store_filters', 'promos_status_filter', 'promos_start_dt_filter','promos_end_dt_filter', 'promos_active_range_filter']
    }
  },
  watch: {
    tenant: {
      handler (newValue, oldValue) {
        if (newValue) {
          if (oldValue) sessionStorage.removeItem('store_filters')
          this.$store.dispatch('initParties')
          const theme = this.$auth?.tenant === 'alliance-retail-group' ? 'arg' : 'light'
          this.$store.commit('setTheme', theme)
        }
      }
    }
  },
  mixins: [userAccess],
  components: { alertbar, Maintenance, VideoMenu, PortalNavMenu },
  created () {
    if (this.$auth?.tenant) {
      this.$store.dispatch('initParties')
      const theme = this.$auth?.tenant === 'alliance-retail-group' ? 'arg' : 'light'
      this.$store.commit('setTheme', theme)
    }
  },
  computed: {
    navLinks () {
      return [
        { text: 'Promotions',  path: '/', icon: 'mdi-view-dashboard', show: true },
        { text: 'Contract Upload',  path: '/contract-upload', icon: 'mdi-tray-arrow-up', show: this.tenantUsesContractUpload },
        // { text: 'Promotion Items', path: '/promo-items', icon: 'mdi-clipboard-text-search-outline' }
      ].filter(link => link.show)
    },
    version () {
      return process.env.VUE_APP_VERSION
    },
    maintenanceStatus () {
      return JSON.parse(process.env.VUE_APP_MAINTENANCE_STATUS)
    },
    tenantsWithLogos() {
      return [        
        'awg',
        'alliance-retail-group'      
      ]    
    }
  },
  methods: {
    logout () {
      this.$auth.logout({
        returnTo: window.location.origin
      })
      for (const key of this.storedKeys) {
        sessionStorage.removeItem(key)
      }
    }
  }
}
</script>
<style>
.v-text-field__slot input::-webkit-calendar-picker-indicator {
  display: none;
}

.v-text-field__slot input[type="date"] {
  cursor: text;
}

.v-text-field__slot input[type="date"]::-webkit-input-placeholder {
  visibility: hidden;
}

span.registeredTrademark {
  position: relative;
  top: -.3rem;
  font-size: .5rem;
  left: -0.05rem;
}

footer {
  font-size: small;
}
</style>