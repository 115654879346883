var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _vm.allowMultipleSubvendors
        ? _c("v-autocomplete", {
            attrs: {
              label: "Subvendor",
              "item-text": "name",
              "item-value": "party_id",
              "item-color": "action",
              items: _vm.subvendors,
              disabled: _vm.readOnlyPromo,
              loading: _vm.loadingSubvendors,
              "hide-no-data": "",
              multiple: "",
              "small-chips": "",
              clearable: "",
              "return-object": "",
              "background-color": "#fff",
              dense: "",
              outlined: "",
              "hide-details": "auto"
            },
            on: { change: _vm.subvendorSelected },
            scopedSlots: _vm._u(
              [
                {
                  key: "selection",
                  fn: function(ref) {
                    var item = ref.item
                    var index = ref.index
                    return [
                      index === 0
                        ? _c(
                            "v-chip",
                            {
                              style:
                                _vm.localSubvendorParties.length > 1
                                  ? "max-width: 70%"
                                  : "max-width: 90%",
                              attrs: { small: "" }
                            },
                            [
                              _c("span", { staticClass: "text-truncate" }, [
                                _vm._v(" " + _vm._s("" + item.name) + " ")
                              ])
                            ]
                          )
                        : _vm._e(),
                      index === 1
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "grey--text text-caption text-truncate",
                              staticStyle: { "max-width": "20%" }
                            },
                            [
                              _vm._v(
                                " (+" +
                                  _vm._s(_vm.localSubvendorParties.length - 1) +
                                  ") "
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  }
                }
              ],
              null,
              false,
              3907192259
            ),
            model: {
              value: _vm.localSubvendorParties,
              callback: function($$v) {
                _vm.localSubvendorParties = $$v
              },
              expression: "localSubvendorParties"
            }
          })
        : _c("v-autocomplete", {
            attrs: {
              label: "Subvendor",
              "item-text": "name",
              "item-value": "party_id",
              "item-color": "action",
              items: _vm.subvendors,
              disabled: _vm.readOnlyPromo,
              loading: _vm.loadingSubvendors,
              "hide-no-data": "",
              "small-chips": "",
              clearable: "",
              "return-object": "",
              "background-color": "#fff",
              dense: "",
              outlined: "",
              "hide-details": "auto"
            },
            on: { change: _vm.subvendorSelected },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-chip",
                      { style: "max-width: 90%", attrs: { small: "" } },
                      [
                        _c("span", { staticClass: "text-truncate" }, [
                          _vm._v(" " + _vm._s("" + item.name) + " ")
                        ])
                      ]
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.selectedSubvendor,
              callback: function($$v) {
                _vm.selectedSubvendor = $$v
              },
              expression: "selectedSubvendor"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }