<template>
  <v-card>
    <v-overlay :absolute="absolute" :value="!existingPromo">
      <v-row align="center">
        <v-btn fab x-small color="white" class="mr-2">
          <v-icon color="orange" x-large>mdi-alert-circle</v-icon>
        </v-btn>
        <h3>Save Promotion Details to Add Parties and Items</h3>
      </v-row>
    </v-overlay>
    <v-toolbar flat color="#fff">
      <v-btn-toggle 
        dense
        v-model="addStores"
        mandatory
        class="btnGroup">
        <v-btn
          height="38"
          :value="false"
          style="border-right:1px solid #9e9e9e;">
          <small>All</small>
          <v-icon class="ml-1" small>mdi-format-list-bulleted</v-icon>
        </v-btn>
        <v-btn
          height="38"
          v-show="id && !readOnlyPromo"
          :value="true"
          style="border-top-right-radius:0px;border-bottom-right-radius:0px;">
          <small>Add</small>
          <v-icon class="ml-1" small>mdi-plus</v-icon>
        </v-btn>
      </v-btn-toggle>
      <v-col v-show="addStores" cols="3" class="pl-0" style="height:64px;">
        <v-text-field
          v-model="search"
          label="Search"
          style="border-top-left-radius:0px;border-bottom-left-radius:0px;"
          append-icon="mdi-window-close"
          @click:append="clearPartySearch"
          background-color="#fff"
          outlined
          dense>
          <template v-slot:prepend-inner>
            <v-icon>mdi-magnify</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col v-show="!addStores" cols="3" class="pl-0" style="height:64px;">
        <v-text-field
          v-model="filter"
          label="Filter"
          style="border-top-left-radius:0px;border-bottom-left-radius:0px;"
          append-icon="mdi-window-close"
          @click:append="filter = ''"
          background-color="#fff"
          outlined
          dense>
          <template v-slot:prepend-inner>
            <v-icon>mdi-filter-outline</v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-toolbar>
    <v-divider/>

  <!-- Promo Parties Table -->
    <v-data-table
      v-if="!addStores"
      :height="tableSize"
      :loading="loading"
      :headers="headers"
      :items.sync="stores"
      :search="filter"
      :items-per-page="storePageSize"
      color="#fff"
      item-key="id"
      loading-text="Loading...Please wait"
      fixed-header
      hide-default-footer
      dense>

      <template v-slot:no-data>
        <v-alert
          v-if="stores.length < 1"
          outlined
          text
          color="orange"
          class="mt-4">
          <template v-slot:default>
            <v-icon color="orange" class="mx-2 pb-1">mdi-alert-circle-outline</v-icon>
            <span>Valid promotion requires at least one party added to promotion.</span>
          </template>
        </v-alert>
      </template>

      <template v-slot:[`item.name`]="{ item }">
       <div>
        {{ item.store_num ? "#" : "" }}{{ item.store_num }} {{ item.name }}
       </div>
      </template>

      <!-- Edit Lump Sum -->
      <template v-slot:[`item.lump_sum`]="{ item }">
        <template v-if="showAllowanceField(item)">
        <v-menu
          v-model="editing"
          :close-on-content-click="false"
          :close-on-click="false"
          bottom
          right
          absolute
          nudge-top="-12px"
          nudge-left="16px"
          offset-y
          max-width="200px">
          <template v-slot:activator="{ on, attrs }">
            <div class="edit-allowance-container"
              :style="!readOnlyPromo ? 'cursor: pointer' : 'cursor: default'"
              v-on="!readOnlyPromo ? on : null"
              v-bind="attrs"
              @click="!readOnlyPromo ? open(item) : null">
              <div style="margin: 0 auto">
                <span style="display: inline-block">
                  $ {{ item.lump_sum }}
                </span>
                <v-icon style="margin-left: 10px" small v-if="!readOnlyPromo">
                  mdi-pencil
                </v-icon>
              </div>
            </div>
          </template>
          <v-card class="pt-2 px-4" tile flat
            v-if="editItem && editItem.store_id === item.store_id">
            <div style="margin-top: 8px">
              <span class="subtitle-2">{{tenant === 'pricechopper' ? 'Edit Ad Fee' : 'Edit Lump Sum'}}</span>
            </div>
            <v-text-field
              ref="allowance"
              class="pt-0 mt-0"
              v-model="editItem.lump_sum"
              :rules="[(v) => Boolean(v) || 'Please enter a value', isValidAllowancePrice]"
              autofocus
              type="number"
              hide-spin-buttons
              prefix="$">
            </v-text-field>
            <v-card-actions class="pt-0 px-0">
              <v-spacer></v-spacer>
              <v-btn
                text
                small
                @click="cancel">
                Cancel
              </v-btn>
              <v-btn
                text
                small
                color="primary"
                :loading="savingItem"
                @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        </template>
      </template>

      <!-- View Promo Party Stores/Members -->
      <template v-slot:[`item.view`]="{ item }">
        <v-tooltip top
          v-if="item && item.party_type_name && item.party_type_name.toLowerCase() !== 'store'">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              small
              color="#1976d2"
              @click="viewParties(item)">
              <v-icon>mdi-view-list</v-icon>
            </v-btn>
          </template>
          <span>View / Exclude</span>
        </v-tooltip>
      </template>

      <!-- Include/Exclude Promo Party -->
      <template v-slot:[`item.include_store`]="{ item }">
        <v-icon :color="item.include_store ? 'green' : 'red'">
          {{ (item.include_store) ? 'mdi-checkbox-marked-circle-outline' : ' mdi-close-circle-outline'}}
        </v-icon>
      </template>

      <!-- Delete Promo Party -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              :disabled="readOnlyPromo"
              v-on="on"
              icon
              small
              @click="removeStoreConfirm(item, false)">
              <v-icon color="red">mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
          <span>Remove</span>
        </v-tooltip>
      </template>
    </v-data-table>

  <!-- Party Search Table -->
    <v-data-table v-else
      id="partiesList"
      :height="tableSize"
      :headers="headers"
      :items.sync="parties"
      :loading="loading"
      loading-text="Loading...Please wait"
      :items-per-page="storePageSize"
      item-key="party_id"
      fixed-header
      hide-default-footer
      dense>

      <!-- View Parties -->
      <template v-slot:[`item.view`]="{ item }">
        <v-tooltip v-if="item.party_type.toLowerCase() !== 'store'" top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon small color="#1976d2" @click="viewParties(item)">
              <v-icon>mdi-view-list</v-icon>
            </v-btn>
          </template>
          <span>View</span>
        </v-tooltip>
      </template>

      <!-- Add Party -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              :disabled="item.disabled"
              icon
              small
              color="green"
              @click="add(item)">
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </template>
          <span>Add</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <ViewParties
      v-if="viewPartiesModal"
      v-model="viewPartiesModal"
      @initStores="initPromoStoreList"
      :addStores="addStores"
      :promo="promo"
      :stores="stores"
      :party="selectedParty"
      :readOnlyPromo="readOnlyPromo"
      :downstreamPartyIds="downstreamPartyIds"
      @close="closeViewParties"
      @addParty="add"
      @addGroupMembers="addGroupMembers"
      @removeGroup="removeStoreConfirm">
    </ViewParties>

    <v-dialog
      v-if="removeDialog"
      justify="center"
      v-model="removeDialog"
      persistent
      width="500">
      <v-card class="ma-0 pa-6">
        <v-row justify="center">
          <v-icon x-large color="orange">mdi-alert-circle-outline</v-icon>
        </v-row>
        <v-row justify="center" class="py-6">
          <h3>Remove {{store.name}}?</h3>
          <div v-if="isLastSourceParty(store.store_id)" style="margin-top: 25px">
            <v-alert
              text
              type="warning"
              border="left">
              <template v-if="lastPartySources.length > 1">
                <p>Items with any of the following sources will also be removed from this promotion:</p>
                <p><strong>{{ lastPartySources.join(', ') }}</strong></p>
              </template>
              <template v-else>
                Any items with the source <strong>{{ lastPartySources[0] }}</strong> will also be removed from this promotion.
              </template>
            </v-alert>
          </div>
        </v-row>
        <v-row justify="center" class="pb-4 pt-2">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                small
                color="red"
                class="elevation-3 white--text"
                @click.stop="removeDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Cancel</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                small
                color="green"
                class="white--text elevation-3 ml-2"
                @click="remove">
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </template>
            <span>Confirm</span>
          </v-tooltip>
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
// import Es from '@/axios/es'
import Search from '@/axios/search-endpoint'
import PromoStore from '@/axios/promotion-store-endpoint'
import PromoAllowance from '@/axios/promo-allowance-endpoints'

// Components
import ViewParties from '@/components/stores/viewParties'

// Mixins
import { buildElasticQuery } from '@/mixins/es_querybuilder'
import { fullWidthTable } from '@/mixins/table'
import { utils } from '@/mixins/utils'
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'
import { formValidation } from '@/mixins/form-validation'

// third party helpers
import { cloneDeep, debounce } from 'lodash'

export default {
  name: 'stores-on-promo',
  data () {
    return {
      absolute: true,
      addStores: false,
      removeDialog: false,
      storeSearchInfo: false,
      viewPartiesModal: false,
      selectedParty: {},
      search: '',
      filter: '',
      store: {},
      stores: [],
      parties: [],
      promoAllowances: [],
      storePageSize: 250,
      from: 0,
      editItem: null,
      editing: false,
      savingItem: false,
      downstreamPartyIds: [],
      addHeaders: [
        { sortable: false, filterable: false, class: 'accent' },
        { text: this.$auth.tenant === 'awg' ? 'Group' : 'Group / Store', sortable: true, filterable: true, value: 'party_name', align: 'left', class: 'accent white--text' },
        { text: 'Type', sortable: true, filterable: true, value: 'party_type', align: 'center', class: 'accent white--text' },
        { text: 'View Members', align: 'center', sortable: false, filterable: false, value: 'view', class: 'accent white--text' },
        { text: 'Add', align: 'center', value: 'actions', sortable: false, filterable: false, class: 'accent white--text' }
      ],
      tableHeaders: [
        { sortable: false, filterable: false, class: 'accent' },
        { text: 'Group / Store', sortable: true, filterable: true, value: 'name', class: 'accent white--text' },
        { text: 'Type', sortable: true, filterable: true, value: 'party_type_name', align: 'center', class: 'accent white--text' },
        { text: 'Lump Sum Allowance', sortable: true, filterable: false, value: 'lump_sum', align: 'center', class: 'accent white--text', tenants: ['awg'] },
        { text: 'Ad Fee', sortable: true, filterable: false, value: 'lump_sum', align: 'center', class: 'accent white--text', tenants: ['pricechopper', 'alliance-retail-group'] },
        { text: 'Included / Excluded', align: 'center', sortable: true, filterable: true, value: 'include_store', class: 'accent white--text' },
        { text: 'View Members', align: 'center', sortable: false, filterable: false, value: 'view', class: 'accent white--text' },
        { text: 'Remove', align: 'center', sortable: false, filterable: false, value: 'actions', class: 'accent white--text' }
      ]
    }
  },
  components: { ViewParties },
  mixins: [
    buildElasticQuery,
    fullWidthTable,
    utils,
    displayAlert,
    userAccess,
    formValidation
  ],
  props: {
    panel_height: Number,
    promo: Object,
    partyItemSources: Array,
    upstreamPartyIds: Array,
    readOnlyPromo: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    search: {
      handler: debounce(function() {
        this.searchParties()
      }, 500)
    },
    addStores: {
      handler (newValue) {
        if (newValue) {
          this.searchParties()
        }
      }
    },
    panel_height: {
      handler (value) {
        this.nonTableHeight = value
      } 
    },
    stores: {
      handler (newValue) {
        if (newValue?.length > 0) {
          this.$emit('enableItemsTab')
          this.$emit('updateShuttleEnabled', this.isShuttleEnabled)
        }
      },
      deep: true
    },
    upstreamPartyIds: {
      handler () {
        if (this.addStores) {
          this.searchParties()
        }
      },
      deep: true
    },
    '$route.query.id': {
      handler (newValue, oldValue) {
        if (newValue && oldValue) {
          this.initPromoStoreList()
        }
      }
    },
    'promo.audit.updated_on': {
      handler (newVal, oldVal) {
        if (newVal && oldVal) {
          if ((this.promoAllowances.length > 0) && !this.promo.lump_sum_allowance || this.promo.lump_sum_allowance == 0) {
            this.initPromoAllowances(true)
          }
        }
      }
    }
  },
  computed: {
    headers () {
      if (this.addStores) {
        return this.addHeaders
      }
      let headers = this.tableHeaders.filter(h => (!h.tenants || h.tenants.includes(this.tenant)))
      if (this.tenant === 'pricechopper' && this.promo.billing_mode?.constant !== 'AD') {
        headers = this.tableHeaders.filter(h => h.value !== 'lump_sum')
      }
      return headers
    },
    inputLabel () {
      return this.addStores ? 'Search' : 'Filter'
    },
    existingPromo () {
      return (this.$route.query.id) ?? false
    },
    id () {
			return (this.$route.query.id) ?? this.promo_id
    },
    shuttleParentPartyTypes () {
      return ['wholesaler', 'distribution center']
    },
    isShuttleEnabled () {
      return this.stores.every(party => {
        return this.shuttleParentPartyTypes.includes(party?.party_type_name?.toLowerCase())
      })
    },
    lastPartySources () {
      if (this.store && this.isLastSourceParty(this.store.store_id)) {
        const sourceIds = this.getLastPartySources(this.store.store_id)
        return sourceIds.map(sourceId => this.$store.getters.getSourceName(sourceId))
      }
      return []
    },
    partySearchTypes () {
      // tenant-specific logic
      return (['spn', 'jbg'].includes(this.$auth.tenant))
        ? ['DISTRIBUTION_CENTER', 'OWNER', 'STORE', 'STORE_GROUP']
        // awg/default
        : ['AD_GROUP', 'REGION_GROUP']
    },
    groupPartyTypes () {
      return ['REGION_GROUP', 'STORE_GROUP']
    },
    memberPartyTypes() {
      return ['STORE', 'AD_GROUP']
    }
  },
  created () {
    this.nonTableHeight = this.panel_height
    if (this.existingPromo) {
      this.initPromoStoreList()
    }
  },
  methods: {
    open (item) {
      this.editItem = cloneDeep(item)
      const numericValue = this.getNumericPrice(item.lump_sum)
      const editValue = (numericValue === 0) ? null : numericValue.toFixed(2)
      this.editItem.lump_sum = editValue
    },
    save () {
      const valid = this.$refs.allowance.validate()
      if (valid) {
        this.setAllowance(this.editItem)
      }
    },
    cancel () {
      this.editItem = null
      this.editing = false
    },
    async setAllowance (promoParty) {
      const { store_id, lump_sum } = promoParty
      const payload = {
        promo_id: this.id,
        party_id: store_id,
        allowance: lump_sum
      }
      const existingAllowance = this.getPartyAllowance(store_id)
      const promise = existingAllowance
        ? PromoAllowance.put(payload)
        : PromoAllowance.post(payload)

      this.savingItem = true
      try {
        await promise
        this.editing = false
        this.editItem = null
        this.$emit('updateHistory')
        this.initPromoStoreList()
      } catch (err) {
        this.handleError(err)
      } finally {
        this.savingItem = false
      }
    },
    getPartyAllowance (partyId) {
      return this.promoAllowances.find(p => p?.party_id === partyId)
    },
    showAllowanceField (party) {
      return party?.party_type_name?.toLowerCase() === 'ad group'
    },
    isLastSourceParty (partyId) {
      const partyItemSource = this.partyItemSources.find(obj => obj.party_id === partyId)
      if (partyItemSource) {
        const lastSources = this.getLastPartySources(partyId)
        return (lastSources.length > 0)
      }
      return false
    },
    getLastPartySources (partyId) {
      const partyItemSource = this.partyItemSources.find(obj => obj.party_id === partyId)
      const lastSources = partyItemSource.sources.filter(sourceId => {
        return (this.partyItemSources.filter(obj => obj.sources.includes(sourceId)).length === 1)
      })
      return lastSources
    },
    async initPromoStoreList () {
      this.loading = true
      let stores = []
      try {
        const { data } = await PromoStore.getStores(this.id, this.storePageSize, this.from)
        if (data?.length > 0) {
          const partyIds = data.map(s => s.store_id)
          this.$emit('setPromoPartyIds', partyIds)
          await this.initPromoAllowances()
          stores = this.formatPromoStores(data)
          if (this.tenant === 'pricechopper') {
            stores.sort((a, b) => {
                if (a.name === 'Cosentino Price Chopper') return -1;
                if (b.name === 'Cosentino Price Chopper') return 1;
                if (a.name === 'McKeever Price Chopper') return -1;
                if (b.name === 'McKeever Price Chopper') return 1;
                return a.name - b.name;
            })
          } else {
            this.sortByKey(stores, 'name')
          }
        }
        this.stores = stores
        await this.initDownstreamPartyIds(this.stores)
        if (this.addStores) {
          this.searchParties()
        }
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    async initDownstreamPartyIds (stores) {
      let partyIds = []
      const DCs = stores.filter(s => s?.party_type_name?.toLowerCase() === 'distribution center')
      if (DCs.length > 0) {
        const results = await this.getAllSettled(DCs.map(party => this.getMemberPartyIds(party, this.memberPartyTypes)))
        partyIds = Array.from(new Set(results))
      }
      this.downstreamPartyIds = partyIds
    },
    async initPromoAllowances (refreshStores = false) {
      const res = await PromoAllowance.getPromoAllowances(this.id)
      this.promoAllowances = res?.data || []
      this.$emit('setPromoAllowances', this.promoAllowances)
      if (refreshStores) {
        this.stores = this.formatPromoStores(this.stores)
      }
    },
    formatPromoStores (parties) {
      return parties.map(party => {
        party.lump_sum = null
        if (party?.party_type_name?.toLowerCase() === 'ad group') {
          const existingAllowance = this.getPartyAllowance(party.store_id)
          let lump_sum = existingAllowance
            ? existingAllowance.allowance
            : 0.00
          lump_sum = this.getNumericPrice(lump_sum)
          party.lump_sum = lump_sum.toFixed(2)
        }
        return party
      })
    },
    initParties () {
      this.searchParties()
    },
    async searchParties () {
      this.loading = true
      let parties = []
      const operator = this.search ? 'and' : 'or'
      const term = this.search || this.buildPartySearchTerm()
      try {
        let { data } = await Search.customerSearch(term, operator)
        if (this.limitAccessByRelatedStores) {
          data = data.filter(p => this.userRelatedPartyIds.includes(p?.party_id))
        }
        if (data?.length > 0) {
          parties = await this.getAllSettled(data.flatMap(async p => {
            if (this.partySearchTypes.includes(p?.party_type)) {
              if (p.party_type === 'AD_GROUP' && p.attributes?.AD_GROUP_IS_ACTIVE === 'False') { // Exclude Ad groups Marked as Inactive
                return []
              }
              p.disabled = await this.isAddDisabled(p)
              return p
            }
            return []
          }))
          parties = this.getOrderedParties(parties)
        }
        this.parties = parties
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    getOrderedParties (parties) {
      const groupParties = []
      parties = parties.flatMap(party => {
        if (this.groupPartyTypes.includes(party?.party_type)) {
          groupParties.push(party)
          return []
        }
        return party
      })
      this.sortByKey(groupParties, 'party_name')
      this.sortByKey(parties, 'party_name')
      return [...groupParties, ...parties]
    },
    buildPartySearchTerm () {
      // initial search to populate table data
      const terms = (this.limitAccessByRelatedStores)
        ? this.userRelatedPartyIds
        : this.partySearchTypes.filter(t => t !== 'STORE')
      return terms.join(' ')
    },
    clearPartySearch () {
      this.search = ''
      this.initParties()
    },
    async add (party) {
      if (this.groupPartyTypes.includes(party?.party_type)) {
        return this.addGroupMembers(party)
      }
      const payload  = {
        promo_id: this.id,
        store_id: party.party_id,
        include_store: true,
        enforce_compliance: true
      }
      try {
        await PromoStore.post(payload)
        this.$emit('updateHistory')
      } catch (err) {
        this.handleError(err)
      } finally {
        this.initPromoStoreList()
      }
    },

    async addGroupMembers (party, memberPartyIds = []) {
      if (memberPartyIds.length === 0) {
        memberPartyIds = await this.getMemberPartyIds(party, this.memberPartyTypes)
      }
      const membersToAdd = memberPartyIds.filter(id => {
        return id && !(this.stores.find(store => store?.store_id === id))
      })
      // add button should be disabled if this is true, but check anyway
      if (membersToAdd.length === 0) return

      const promoId = this.$route.query.id
      const promises = membersToAdd.map(partyId => {
        const payload  = {
          promo_id: promoId,
          store_id: partyId,
          include_store: true,
          enforce_compliance: true
        }
        return PromoStore.post(payload)
      })
      try {
        const { rejected } = await this.getAllSettled(promises, true)
        if (rejected.length > 0) throw rejected
        this.$emit('updateHistory')
      } catch (err) {
        this.handleError(err)
      } finally {
        this.initPromoStoreList()
      }
    },
    async isAddDisabled (party) {
      if (this.groupPartyTypes.includes(party?.party_type)) {
        const memberPartyIds = await this.getMemberPartyIds(party, this.memberPartyTypes)
        return this.allMembersAdded(memberPartyIds)
      }
      const includedOnPromo = this.stores.some(store => store?.store_id === party.party_id)
      const isUpstream = this.upstreamPartyIds.includes(party.party_id)
      const isDownstream = this.downstreamPartyIds.includes(party.party_id)
      return includedOnPromo || (isUpstream || isDownstream)
    },
    allMembersAdded (memberPartyIds) {  
      if (memberPartyIds.length > 0) {
        return memberPartyIds.every(partyId => {
          return Boolean(this.stores.find(store => store?.store_id === partyId))
        })
      }
      return false
    },
    async update (store) {
      const promoStore = {
        'promo_id': this.id,
				'store_id': store.store_id,
				'include_store': !store.include_store,
				'enforce_compliance': store.enforce_compliance
      }
      await PromoStore.put(store.id,promoStore)
        .then(() => {
          store.include_store = !store.include_store
          this.$emit('updateHistory')
        }).catch(err => {
          this.handleError(err)
        })
    },
    async remove () {
      await PromoStore.delete(this.store.id)
        .then(() => {
          this.$emit('updateItemSources', this.store.store_id)
          const index = this.stores.findIndex(store => store.id === this.store.id)
          this.stores.splice(index, 1)
        }).catch(err => {
         this.handleError(err)
        }).finally(() => {
          this.removeDialog = false
          this.initPromoStoreList()
        })
    },
    removeStoreConfirm (store, skipConfirm) {
      this.store = store

      if (skipConfirm) {
        this.remove()
      } else {
        this.removeDialog = true
      }
    },
    /*
    async searchStores  () {
      this.loading = true
      const query = this.buildPartySearchQuery()
      const postObj = {
        body: {
          from: this.from,
          size: this.pageSize,
          query,
          sort: [
            {'party_type.constant.keyword': {order: 'asc'}},
            {'name.keyword': {order: 'asc'}}
          ]
        }
      }
      // let parties = []
      let total = 0
      try {
        const res = await Es.post('CUSTOMER', '/_search?rest_total_hits_as_int', postObj)
        if (res?.hits?.hits?.length > 0) {
          // parties = res.hits.hits.map(result => result._source)
          total = res.hits.total
        }
        // this.parties = parties
        this.serverItemsLength = total
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    buildPartySearchQuery () {
      const query = cloneDeep(this.buildElasticQuery('CUSTOMER'))
      // tenant-specific logic (ipro)
      if (this.$auth.tenant === 'iprosystems') {
        const responsibleParty = this.promo?.responsible_party_id || ''
        query.bool.filter.push({
          term: {'related_party.related_party_id.keyword': responsibleParty}
        })
      } else {
        query.bool.filter.push({
          terms: {
            'party_type.constant.keyword': [...this.partySearchTypes]
          }
        })
      }
      if (this.limitAccessByRelatedStores) {
        query.bool.filter.push({
          terms: {'id.keyword': [...this.userRelatedPartyIds ]}
        })
      }
      return query
    },
    */
    viewParties (party) {
      this.selectedParty = party
      this.viewPartiesModal = true
    },
    closeViewParties (update = false) {
      this.viewPartiesModal = false
      this.selectedParty = null
      if (update) this.initPromoStoreList()
    }
  }
}
</script>
<style>
.btnGroup {
  border-top: 1px solid #9e9e9e;
  border-left: 1px solid #9e9e9e;
  border-bottom: 1px solid #9e9e9e;
  border-top-right-radius:0px !important;
  border-bottom-right-radius:0px;
}

.edit-allowance-container {
  display: grid;
  position: relative;
}
</style>