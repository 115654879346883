var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c("v-autocomplete", {
        attrs: {
          items: _vm.vendors,
          "item-text": "display_name",
          "item-value": "party_id",
          "item-color": "action",
          label: "Vendor",
          disabled: _vm.readOnlyPromo,
          loading: _vm.loadingVendors,
          "search-input": _vm.vendorSearch,
          "background-color": "#fff",
          "error-messages": _vm.billingPartyErrors,
          "hide-no-data": "",
          multiple: "",
          "cache-items": "",
          "return-object": "",
          dense: "",
          outlined: "",
          "hide-details": "auto"
        },
        on: {
          "update:searchInput": function($event) {
            _vm.vendorSearch = $event
          },
          "update:search-input": function($event) {
            _vm.vendorSearch = $event
          },
          blur: _vm.queueBillingPartyChanges
        },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function(ref) {
              var item = ref.item
              var index = ref.index
              return [
                index === 0
                  ? _c(
                      "v-chip",
                      {
                        style:
                          _vm.localBillingParties.length > 1
                            ? "max-width: 70%"
                            : "max-width: 90%",
                        attrs: { small: "" }
                      },
                      [
                        _c("span", { staticClass: "text-truncate" }, [
                          _vm._v(" " + _vm._s("" + item.display_name) + " ")
                        ])
                      ]
                    )
                  : _vm._e(),
                index === 1
                  ? _c(
                      "span",
                      {
                        staticClass: "grey--text text-caption text-truncate",
                        staticStyle: { "max-width": "20%" }
                      },
                      [
                        _vm._v(
                          " (+" +
                            _vm._s(_vm.localBillingParties.length - 1) +
                            ") "
                        )
                      ]
                    )
                  : _vm._e()
              ]
            }
          }
        ]),
        model: {
          value: _vm.localBillingParties,
          callback: function($$v) {
            _vm.localBillingParties = $$v
          },
          expression: "localBillingParties"
        }
      }),
      _c(
        "v-dialog",
        {
          attrs: { justify: "center", persistent: "", width: "600" },
          model: {
            value: _vm.showDialog,
            callback: function($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c("v-card-title", [_vm._v("Confirm Changes")]),
              _c(
                "v-alert",
                {
                  attrs: { text: "", type: "warning" },
                  model: {
                    value: _vm.showDialog,
                    callback: function($$v) {
                      _vm.showDialog = $$v
                    },
                    expression: "showDialog"
                  }
                },
                [
                  _c("p", [
                    _vm._v(" You've removed the following vendors:"),
                    _c("br"),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.removedVendors.join(", ")))
                    ])
                  ]),
                  _c("p", [
                    _vm._v(
                      " If you continue, any existing items associated with these vendors will be removed from this promotion. "
                    )
                  ])
                ]
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { staticClass: "mr-4", on: { click: _vm.cancelChanges } },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: { color: "red" },
                      on: { click: _vm.confirmChanges }
                    },
                    [_vm._v(" Confirm ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }