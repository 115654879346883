export const userAccess = {
  computed: {
    entities () {
      return ['promo', 'promo_item', 'promo_item_group', 'promo_store']
    },
    adminRoles () {
      return ['promo.admin', 'client-admin', 'pce-admin', 'jbg-admin']
    },
    user () {
      return this.$auth.user
    },
    tenant () {
      return this.$auth.tenant
    },
    // tenant-specific logic
    tenantRequiresVendor () {
      return ['awg', 'pricechopper', 'alliance-retail-group', 'jbg'].includes(this.tenant)
    },
    tenantIgnoresCompliance () {
      return ['pricechopper'].includes(this.tenant)
    },
    tenantDefaultVariance () {
      return {
        awg: 0.05
      }
    },
    tenantHasSourceDistinction () {
      return ['awg', 'alliance-retail-group', 'jbg'].includes(this.tenant)
    },
    userRoles () {
      return this.$auth.userRoles || []
    },
    userPermissions () {
      return this.$auth.permissions || []
    },
    userRelatedParties () {
      return this.$store.getters.userRelatedParties
    },
    userRelatedPartyIds () {
      return this.$store.getters.userRelatedPartyIds
    },
    relatedVendorPartyIds () {
      return this.userRelatedParties.flatMap(party => {
        return (party?.party_type_constant === 'VENDOR' && party?.attributes?.VENDOR_ID)
          ? party.id
          : []
      })
    },
    relatedSubvendorPartyIds () {
      return this.userRelatedParties.flatMap(party => {
        return (party?.party_type_constant === 'SUBVENDOR')
          ? party.id
          : []
      })
    },
    // to return the full object not just the id
    relatedVendorParties () {
      return this.userRelatedParties.flatMap(party => {
        return (party?.party_type_constant === 'VENDOR' && party?.attributes?.VENDOR_ID)
          ? party
          : []
      })
    },
    relatedSubvendorParties () {
      return this.userRelatedParties.flatMap(party => {
        return (party?.party_type_constant === 'SUBVENDOR')
          ? party
          : []
      })
    },
    relatedAdGroupsPartyIds () {
      return this.userRelatedParties.flatMap(party => {
        return (party?.party_type_constant === 'AD_GROUP')
          ? party.id
          : []
      })
    },
    relatedDistCenterPartyIds () {
      return this.userRelatedParties.flatMap(party => {
        return (party?.party_type_constant === 'DISTRIBUTION_CENTER')
          ? party.id
          : []
      })
    },
    relatedStorePartyIds () {
      return this.userRelatedParties.flatMap(party => {
        return (party?.party_type_constant === 'STORE')
          ? party.id
          : []
      })
    },
    hasPortalAccess () {
      const readScopes = this.entities.map(entity => `${entity}:read`)
      const hasReadAccess = readScopes.every(scope => this.userPermissions.includes(scope))
      return !this.userPermissions.includes('promo:no_access') && hasReadAccess
    },
    hasWriteAccess () {
      return this.writePermissions.every(scope => this.userPermissions.includes(scope))
    },
    writePermissions () {
      const actions = ['create', 'read', 'update']
      return this.entities.flatMap(entity => {
        return actions.map(action => `${entity}:${action}`)
      })
    },
    isAdmin () {
      return this.adminRoles.some(role => this.userRoles.includes(role))
    },
    hasStatusAdminAccess () {
      return this.isAdmin || this.userPermissions.includes('promo:status_admin')
    },
    limitAccessByRelatedStores () {
      return !this.isAdmin && this.userPermissions.includes('promo:related_stores')
    },
    limitAccessByRelatedVendors () {
      return !this.isAdmin && this.userPermissions.includes('promo:related_vendors')
    },
    limitAccessByRelatedSubvendors () {
      return !this.isAdmin && this.userPermissions.includes('promo:related_subvendors') //May need to change
    },
    limitAccessByUserParties () {
      return (this.limitAccessByRelatedStores || this.limitAccessByRelatedVendors)
    },
    limitCategoriesByUserParties () {
      return !this.isAdmin && this.userPermissions.includes('promo:related_party_categories')
    },
    promo_categories () {
      let promoCategories = this.$store.getters.promo_categories
      if (this.tenant === 'pricechopper') {
        promoCategories = promoCategories.filter(cat => {
          return !this.pceHiddenCategories.includes(cat?.name)
        })
      }
      return promoCategories
    },
    userPromoCategories () {
      let promoCategories = this.promo_categories
      if (this.limitCategoriesByUserParties) {
        promoCategories = promoCategories.filter(cat => {
          return this.userPromoCategoryIds.includes(cat.id)
        })
      }
      if (this.limitAccessByRelatedVendors && this.vendorHiddenCategories.length > 0) {
        promoCategories = promoCategories.filter(cat => {
          return !this.vendorHiddenCategories.includes(cat?.name)
        })
      }
      if (this.tenant === 'pricechopper') {
        promoCategories = promoCategories.filter(cat => {
          return !this.pceHiddenCategories.includes(cat?.name)
        })
      }
      return promoCategories.filter(c => {
        return ![...this.readOnlyCategories, 'Hot Buy Scan', 'Hot Buy Billback'].includes(c?.name)
      })
    },
    readOnlyCategories () {
      if (this.tenant === 'alliance-retail-group' && this.isAdmin) {
        return this.$store.state.readOnlyCategories.filter(category => category !== 'AMAP');
      }
      return this.$store.state.readOnlyCategories
    },
    pceHiddenCategories () {
      const hiddenCats = this.$store.state.pceHiddenCategories
      // TO DO: remove once EBA-related code is ready for prod
      return process.env.VUE_APP_ENV === 'production' 
        ? [...hiddenCats, 'Early Buy Allowance']
        : hiddenCats
    },
    vendorHiddenCategories () {
      return this.$store.state.vendorHiddenCategories[this.tenant]
    },
    userPromoCategoryIds () {
      return this.$store.getters.userPromoCategoryIds
    },
    tenantUsesContractUpload(){
      return  ['alliance-retail-group', 'pricechopper', 'jbg'].includes(this.$auth.tenant)
    }
  },
  methods: {
    hasScope (scope) {
      return this.userPermissions.includes(scope)
    }
  }
}